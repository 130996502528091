@import "../../assets/constStyle";


.wrapper {
  margin: 0 auto;
  width: 1920px;
  flex-direction: row;
}

.search-bar {
  height: 65px;
  //border-bottom: 2px solid black;
  justify-content: center;
  align-items: center;
}

.search-bar_button {
  width: 220px;
  height: 34px;
  background-color: $primaryColor;
  border-radius: 40px;
  cursor: pointer;

  p {
    margin: auto 0 auto 5%;
    font-size: 14px;
    // margin-left: 5%;
  }

  img {
    margin-left: 10%;
    width: 18px;
  }

  input {
    margin: auto 0 auto 5%;
    font-size: 14px;
    background: none;
    border: none;
    color: white;
  }

  input:active, :hover, :focus  {
    background: none;
    border: none;
     outline: 0;
    outline-offset: 0;
  }

}

.user {
  width: 90%;
  height: 61px;
  margin-bottom: 5px;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: space-between;

  .imag-user {
    width: 54px;
    height: 54px;
    overflow: hidden;
    border-radius: 27px;

    img {
      height: 54px;
      width: 54px;
      object-fit: cover;
    }
  }

  .info{
    width: 60%;
    margin-left: 5%;
    font-size: 14px;


    .firststr {
      margin: 0;
    }

    .secondstr{
      color: rgba(#ABABAB, 0.65);
      margin: 0;
      padding-top: 5%;
    }
  }

  .social-network{
    width: 10%;

    p {
      font-size: 12px;

     }
 }

  .unread-user{
    width: 15px;

    img {
      width: 10px;
    }
  }
}

.user:hover {
  background-color: #BE8580;
  border-radius: 10px;
}

.active-chat {
  background-color: #BE8580;
  border-radius: 10px;
}


.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // background-color: $primaryColor;
    color: white;
  }

.left-side {
  display: flex;
  flex-direction: column;
  width: 20%;
  min-width: 280px;
  height: 100%;
  background-color: #282828;
  // background-color: $thirdColor;
  overflow: hidden;
  margin: 0;
  transition: .5s ease;
}

.users-list {
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  align-items: center;
}

.users-list::-webkit-scrollbar {
  display: none;
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 82%;
  height: 100%;
  background: linear-gradient(151.32deg, #FFD585 -45.44%, rgba(226, 50, 255, 0) 108.03%), #7D486A;
}

.mobile{
  display: none;
}

@media screen and (max-width: 1050px) {
    .messageZone {
    width: 70%;
  }

  .interface {
    width: 70%;
  }

}


@media screen and (max-width: 840px) {
  .mobile{
    display: block;
  }
  .desktop{
    display: none;
  }

  .right-side {
    width: 100%;
  }

  .hamburger-lines {
    padding-right: 35px;
  }

  .close-left-side{
    width: 15px;
    padding-left: 15px;
    padding-right: 25px;
  }

  .left-side {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 11;
    top: 0;
    width: 50%;

    &.close {
      left: -350px;
    }
  }

  .messageZone {
    width: 90%;
  }

  .interface {
    width: 90%;
  }
}
