@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,400;0,700;1,400&display=swap');
@import "./assets/constStyle";


// ---main---

html, body, #root {
  margin: 0;
  padding: 0;
  font-family: $primaryFont;
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
}

.clickable {
  cursor: pointer;
}

a {
  font-size: 14px;
  color: $secondaryColor
}

button {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: $secondaryColor;
  color: white;
  outline: none;
  border: none;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  margin-top: 60px;
  margin-bottom: 20px;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(white, 0.1);
  border-radius: 50px;
}

// ---/main---


// ---login and register---

.logo {
  font-size: 36px;
  color: $secondaryColor;
  font-weight: bold;
  text-align: center;
  margin: 50px 0;
}

.loginContainer {
  width: 100%;
  min-height: 100vh;
  padding: 50px 20px;
  background: linear-gradient(151.32deg, #FFD585 -15.44%, rgba(226, 50, 255, 0) 108.03%), $secondaryColor;
  box-sizing: border-box;
}

.inner {
  max-width: 400px;
  margin: 5% auto 0;
  padding: 7%;
  border: 2px;
  border-radius: 25px;
  background-color: $primaryColor;
}

.title {
  font-size: 26px;
  text-align: center;
  color: rgba(white, 0.65);
  margin-bottom: 50px;
}

.input-field {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(white, 0.1);;
  outline: none;
  color: white;
  box-sizing: border-box;
}

.input-container {
  position: relative;
  margin-top: 20px;

  img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.switchOption {
  margin-top: 50px;
  color: rgba(white, 0.33);
  text-align: center;

  b {
    font-weight: 500;
    color: $secondaryColor;
  }
}

.authContainer {
  background-color: $primaryColor;
  width: 100%;
  min-height: 100vh;
  height: 100%;
}

.loader {
  width: 30px;
  height: 30px;
  border: 2px solid transparent;
  border-top-color: white;
  border-radius: 30px;
  animation: rotate 500ms linear infinite;
}

.centerLoader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

@keyframes rotate {
  to {
    transform: rotateZ(360deg);
  }
}

.errorHandler {
  background-color: rgba(red, 0.40);
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  font-size: 15px;

  position: relative;

  img {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    cursor: pointer;
  }
}

// ---/login and register---
